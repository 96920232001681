/* eslint-disable react/jsx-no-literals */
import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container, ContentrArea } from '@ttstr/components';

const Newsletter: React.FC = () => {
  const { t } = useTranslation();
  return (
    <article className="my-5">
      <Helmet>
        <title>{t(`Kopf und Steine Newsletter`)}</title>
        <body className="newsletter-page" />
      </Helmet>
      <Container className="d-flex justify-content-center align-items-center flex-column pb-5">
        <ContentrArea id="main-content" />
      </Container>
    </article>
  );
};

export default React.memo(Newsletter);
